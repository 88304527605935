.input-container{
    width: 460px;
    height: 70px;
    opacity: 0px;
    display: flex;
    align-items: center;
}
.input{
    width: 418px;
    height: 28px;
    font-size: medium;
    font-family: Roboto;
    padding: 14px 20px 14px 20px;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid transparent;
    background-color: #FFF;
}