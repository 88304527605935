
  .custom-heading {
    font-family: 'Roboto', sans-serif;
    color: #FFF;
    font-size: 64px;
    font-weight: 600;
    line-height: 75px;
    text-align: left; /* Выравнивание текста по левому краю */
    width: 460px;     /* Ширина заголовка */
    height: 75px;     /* Высота заголовка */
    margin: 0;        /* Убираем внешние отступы */
    padding: 0;       /* Убираем внутренние отступы */
   
  }