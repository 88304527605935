.account-container{
    
    display: flex;      /* используем Flexbox для контейнера */
    justify-content: space-between; /* равномерное распределение элементов */         /* полностью прозрачный элемент */
    width: 221px;
    height: 35px;
    gap: 5px;
    top: 50px;
    padding-left: 120px;
    padding-top: 45px;

}

