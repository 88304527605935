.account{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400px;
    line-height: 18.75px;
    text-align: left;
    color: #FFF;
}

.account-container{
    width: 10px;
    height: 19px;
    gap: 0px;
}