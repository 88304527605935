.remember-me-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    width: auto;       /* ширина зависит от контента */
    min-width: 158px;  /* минимальная ширина */
    height: auto;      /* высота зависит от контента */
    min-height: 24px;  /* минимальная высота */
    gap: -5px;  
    padding-bottom: 10px;           
  }
  
  .checkbox-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .remember-me{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #FFF;
  }