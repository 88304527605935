body, html{
    background-color: #D9D9D9;
    margin: 0;
    padding: 0;
  }

.error {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #FFF;
  display: flex;
  align-items: center;
  padding: 0;

}


.main {
  width: 620px;
  min-height: 100vh;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;   
  box-sizing: border-box;
  padding-top: 45px;
  padding-left: 90px; 
  padding-right: 60px; 
  background-color: #5864FF;
  border-top-right-radius: 30px; 
  border-bottom-right-radius: 30px; 
    
}

