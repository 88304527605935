.button{
    width: 460px;
    height: 52px;
    padding: 14px 20px 14px 20px;
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
    border: 1px solid transparent;
    background-color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.09px;
    color: #5864FF;
}