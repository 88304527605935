.registration{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: right;
    width: 116px;
    height: 19px;
    gap: 0px;
    cursor: pointer;
    text-decoration: underline;
    color: #FFF;
}

.registration-container{
    width: 96px;
    height: 19px;
    gap: 0px;
    
}